@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Raleway&display=swap');
.bg-bendoevent{
    background-color: #021743;
}
.sidelog{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.sidelog .appname{
    margin-top: 25px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: 500;
}
.bg-gray{
    background-color: #dedfe0;
}
html,body{
    background-color: #dedfe0;
}
.myNav{
    height: 70px;
    background-color: #021743;
    width: 100%;
    -webkit-box-shadow: -1px 13px 13px -13px rgba(0,0,0,0.51);
    -moz-box-shadow: -1px 13px 13px -13px rgba(0,0,0,0.51);
    box-shadow: -1px 13px 13px -13px rgba(0,0,0,0.51);
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}
.imgnav{
    width: 50px;
}
.myNavAP{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
}
.cursor-pointer{
    cursor: pointer;
}
.bg-primary-gradient{
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    background-color: #021743;
}
.mycard{
    width: 100%;
}
.myLoader{
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    bottom:0;
    right:0;
    left:0;
    z-index: 999999;
}

.icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  .input-group-text{
      background-color: #ccc!important;
      padding: 0.375rem 0.75rem!important;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto!important;
    overflow-y: unset!important;
    -webkit-overflow-scrolling: touch;
}

position-relative{
    position: relative;
}

.opacity-0 {
    opacity:0!important;
  }
  .opacity-1 {
    opacity:0.2!important;
  }
  .opacity-2 {
    opacity:0.4!important;
  }
  .opacity-3 {
    opacity:0.6!important;
  }
  .opacity-4 {
    opacity:.8!important;
  }
  .opacity-5 {
    opacity:1!important;
  }
  .actionBtn{
      cursor: pointer;
      padding: 3px;
      transition: .5s;
      margin-right: 5px;
  }
  .form-check input[type="checkbox"], .form-check-radio input[type="radio"] {
    opacity: 1;
    position: absolute;
    visibility: visible;
}
.table-sm th, .table-sm td {
    padding: 0.3rem!important;
}

.nowrapText{
    white-space: nowrap!important;
}

a:hover{
  text-decoration: none!important;
}
.prevHead{
  background-color: #021743;
  color: white;
  border-bottom: 1px white solid;
  padding: 10px 15px!important;
}

.smscontainer{
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #ccc;
  color: #021743;
  max-height: 300px;
  overflow: hidden;
}

.phonecontainer{
  background-image: url('../img/iphone_overlay.png');
  height: 600px;
  width: 300px;
  background-repeat: no-repeat;
  position: relative;
  margin: auto;
}

.phoneinner{
  position: absolute;
  z-index: 2;
  top:72px;
  left: 35px;
  width: 226px;
  background-color:white;
}
.afiliationLinkInput{
  border:unset!important;
  padding: 0!important;
}
.card2{
  border-radius: 6px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    border: 0 none;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}